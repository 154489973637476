
// @ts-nocheck


export const localeCodes =  [
  "en"
]

export const localeLoaders = {
  "en": [{ key: "../../../layers/core/locales/en.json", load: () => import("../../../layers/core/locales/en.json" /* webpackChunkName: "locale__work_portal_portal_layers_core_locales_en_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../../../layers/core/nuxt-i18n.ts?hash=d9a4cc97&config=1" /* webpackChunkName: "__nuxt_i18n_ts_d9a4cc97" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "/work/portal/portal/layers/core/server/localeDetector.ts",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": true
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./nuxt-i18n.ts",
  "locales": [
    {
      "code": "en",
      "files": [
        "/work/portal/portal/layers/core/locales/en.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "./locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "files": [
      {
        "path": "/work/portal/portal/layers/core/locales/en.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
