import validate from "/work/portal/portal/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.3.3__ioredis@5.4.1__ebnisdrh3kwfxfl4quj4naecoi/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45preview_45mode_45global from "/work/portal/portal/layers/core/middleware/01.preview-mode.global.ts";
import _02_45auth_45global from "/work/portal/portal/layers/core/middleware/02.auth.global.ts";
import manifest_45route_45rule from "/work/portal/portal/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.3.3__ioredis@5.4.1__ebnisdrh3kwfxfl4quj4naecoi/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45preview_45mode_45global,
  _02_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  documents: () => import("/work/portal/portal/layers/core/middleware/documents.ts")
}