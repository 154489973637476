<template>
  <div class="layout-menu-mobile">
    <button
      class="mobile-menu-button"
      data-testid="mobile-menu-button"
      type="button"
      @click="sidebarMobileMenuIsVisible = !sidebarMobileMenuIsVisible"
    >
      <MenuIcon
        :size="KUI_ICON_SIZE_50"
        :title="t('actions.toggle_menu')"
      />
    </button>

    <ClientOnly>
      <Teleport to="#teleports">
        <LayoutSidebar
          align="left"
          class="mobile-menu-sidebar"
          :class="{ 'open': sidebarMobileMenuIsVisible }"
          mobile-full-width
        >
          <CloseButton
            class="mobile-menu-close-button"
            data-testid="mobile-menu-close-button"
            :size="KUI_ICON_SIZE_60"
            @click="sidebarMobileMenuIsVisible = false"
          />
          <LayoutMenuHeader mobile-menu />
        </LayoutSidebar>
      </Teleport>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { MenuIcon } from '@kong/icons'
import { KUI_ICON_SIZE_50, KUI_ICON_SIZE_60 } from '@kong/design-tokens'
import { useEventListener } from '@vueuse/core'

const { t } = useI18n()
const router = useRouter()
const { sidebarMobileMenuIsVisible } = storeToRefs(useLayoutStore())
const { appearance } = storeToRefs(usePortalStore())
const accentColor = (appearance.value?.stylesheets?.global?.main?.data as Record<string, any>)?.colors?.['section_colors-footer']
const textColor = (appearance.value?.stylesheets?.global?.main?.data as Record<string, any>)?.colors?.['section_colors-body']

router.afterEach(() => {
  // Close the sidebar when navigating to a new route
  sidebarMobileMenuIsVisible.value = false
})

useEventListener(window, 'resize', () => {
  sidebarMobileMenuIsVisible.value = false
})
</script>

<style lang="scss" scoped>
.layout-menu-mobile,
.mobile-menu-sidebar {
  display: flex;

  @media (min-width: $kui-breakpoint-phablet) {
    display: none;
  }
}

.mobile-menu-sidebar {
  background-color: v-bind(accentColor);
  color: v-bind(textColor);
  padding: var(--kui-space-110, $kui-space-110);
}

.mobile-menu-button {
  align-items: center;
  background-color: var(--kui-color-background-transparent, $kui-color-background-transparent);
  border: none;
  color: var(--kui-color-text-inverse, $kui-color-text-inverse);
  cursor: pointer;
  display: flex;
  gap: var(--kui-space-20, $kui-space-20);
  justify-content: center;
  padding: var(--kui-space-0, $kui-space-0);
}

.mobile-menu-close-button {
  color: v-bind(textColor);
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>
