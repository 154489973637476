// TODO: Long-term, this auth middleware should only be applied to pages and routes that are private, not public, instead of being global

/**
 * Global authentication middleware for Nuxt routes.
 * This middleware checks if the route is public or private and handles authentication accordingly.
 * If the route is public, it allows navigation to the route.
 * If the route is private, it redirects the user to the login page if not authenticated.
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const { info } = storeToRefs(usePortalStore())
  const sessionStore = useSessionStore()
  const { session } = storeToRefs(sessionStore)

  // If Portal is public
  if (info.value?.is_public === true) {
    // Redirect the user to the homepage if they are attempting to visit any unneeded paths when a Portal is public.
    // Important: never include `/logout` in this list.
    const redirectPublicPortalPaths = ['/login', '/login/sso', '/register', '/forgot-password', '/reset-password']
    if (redirectPublicPortalPaths.includes(to.path) && to.path !== '/') {
      return navigateTo('/', {
        replace: true,
      })
    }

    // Allow navigation to the route since the portal is public
    return
  }

  // If the portal is private
  if (info.value?.is_public === false) {
    // If attempting to navigate to a Custom Page, allow navigation and the `PageMdcComponent.vue` component
    // will handle the authentication logic based on if the page is public or private.
    if (to.name === 'page_slug') {
      return
    }

    // If the user is not authenticated and attempting to visit a page other than one of the allowed paths
    const unauthenticatedAllowedPaths = [
      // Pages
      '/login', '/login/sso', '/logout', '/register', '/forgot-password', '/reset-password',
      // Sitemap/Robots
      '/robots.txt', 'sitemap.xml', 'sitemap_index.xml', '/__og-image__',
    ]
    if (!session.value.authenticated && !unauthenticatedAllowedPaths.includes(to.path)) {
      // Store the current route in the cookie to redirect on login
      sessionStore.setLoginReturnPath(to.fullPath)

      // Redirect the user to the /login page
      return navigateTo({
        path: '/login',
      })
    }

    // If the user is already authenticated and attempting to visit a path that should not be
    // accessible when authenticated, redirect to the homepage
    const authenticatedDisallowedPaths = ['/login', '/register', '/forgot-password', '/reset-password']
    if (session.value.authenticated && authenticatedDisallowedPaths.includes(to.path)) {
      // Check if we have a stored login path locally before we clear the cookie
      const userReturnToPath = sessionStore.getLoginReturnPath() || '/'
      // Clear the stored return path cookie
      sessionStore.setLoginReturnPath(null)
      // Redirect the user to the path they were trying to access before logging in, or the homepage
      return navigateTo(userReturnToPath, {
        replace: true,
      })
    }
  }
})
