import { parse as parseSetCookie, splitCookiesString } from 'set-cookie-parser'
import { appendResponseHeader } from 'h3'
import { parse as parseCookieHeader, serialize } from 'cookie-es'
import { defu } from 'defu'
import type { FetchContext, FetchOptions } from 'ofetch'
import type { Cookie } from 'set-cookie-parser'
import type { H3Event } from 'h3'
import type { OpenFetchClientName } from '#build/open-fetch'

export default defineNuxtPlugin({
  name: 'open-fetch-sdks',
  setup() {
    // Only call composables at the root of the plugin setup
    const { openFetch: clients, cookies: { portalAccessToken, portalRefreshToken, portalSessionToken }, portal: { origin: localPortalOrigin } } = useRuntimeConfig().public
    const localFetch = useRequestFetch()
    const portalConfigStore = usePortalStore()
    const { info } = storeToRefs(portalConfigStore)
    const sessionStore = useSessionStore()
    const { session } = storeToRefs(sessionStore)
    const eventFetch = useRequestFetch()
    const cookieRequestHeader = useRequestHeader('cookie')
    const cookieAccess = useCookie(portalAccessToken)
    const cookieRefresh = useCookie(portalRefreshToken)
    const route = useRoute()

    // Create private cookie variables
    let cookiePortalAccessToken: string = ''
    let cookiePortalRefreshToken: string = ''

    /** Automatically retry the request if an error happens and if the response status code is included in this array */
    const defaultRetryStatusCodes = [408, 425, 429, 500, 502, 503, 504]

    const portalApiOptions: FetchOptions = {
      retryStatusCodes: [
        ...defaultRetryStatusCodes, // Use the default codes
        401, // also include 401 for attempting to refresh the session auth cookie
        404, // also include 404, **only** for attempting to refresh the session auth cookie when a 404 is returned from the Portal Pages API
      ],
      retry: 1, // Number of retry attempts. Do not set to zero, as it will not retry the request
      retryDelay: 100, // Delay between retries in milliseconds
      timeout: 20000, // Timeout after 20 seconds
      async onRequest({ request, options }: FetchContext) {
        let localRequestCookieHeader: string = ''
        // If the portal is not public and the access cookie is present and the request is to a Portal API endpoint, append the cookie to the request
        const initialCookies = parseCookieHeader(cookieRequestHeader || '')
        for (const initialCookieName in initialCookies) {
          // Update the local cookiePortalAccessToken value if present
          if (initialCookieName === portalAccessToken) {
            cookiePortalAccessToken = initialCookies[initialCookieName]
            continue
          }

          // Update the local cookiePortalRefreshToken value if present
          if (initialCookieName === portalRefreshToken) {
            cookiePortalRefreshToken = initialCookies[initialCookieName]
            continue
          }
        }

        if (info.value?.is_public === false && cookiePortalAccessToken && String(request || '').includes('/api/v')) {
          // Combine the cookies into a single string, and parse them into an object
          const parsedCookies = parseCookieHeader(`${cookieRequestHeader}; ${serialize(portalAccessToken, cookiePortalAccessToken)}`)
          // Loop through the parsed cookies and append them to the local cookie header
          for (const cookie in parsedCookies) {
            // Always strip out the portalSessionToken, and if not hitting the refresh endpoint, strip out the portalRefreshToken since it is only needed when hitting the refresh or logout endpoints
            if (cookie === portalSessionToken || (cookie === portalRefreshToken && !String(request || '').includes('/refresh') && !String(request || '').includes('/logout'))) {
              continue
            }
            localRequestCookieHeader += `${serialize(cookie, parsedCookies[cookie])}; `
          }
        } else if (info.value?.is_public === true && cookiePortalAccessToken) {
          // Combine the cookies into a single string, and parse them into an object
          const parsedCookies = parseCookieHeader(cookieRequestHeader || '')
          // Loop through the parsed cookies adding them to the request cookies, skipping the auth cookies
          for (const cookie in parsedCookies) {
            // Always strip out the portalSessionToken, and no need for auth cookies if the portal is public
            if (cookie === portalAccessToken || cookie === portalRefreshToken || cookie === portalSessionToken) {
              continue
            }
            localRequestCookieHeader += `${serialize(cookie, parsedCookies[cookie])}; `
          }
        }

        // Ensure headers are bound to request
        options.headers = {
          ...options.headers || {},
          // @ts-ignore: Important: include the cookie header in the request
          Cookie: localRequestCookieHeader,
        }

        // Create an AbortController instance in case we want to cancel the request
        const controller = new AbortController()
        // If the Portal is not public and the user is not authenticated and there are zero retries left, abort the request (this means that a session refresh has already occurred)
        if (info.value?.is_public === false && !cookiePortalRefreshToken && !session.value.authenticated && options.retry === 0) {
          // TODO: expose trace id in the UI on the login page? (or in the console)
          options.signal = controller.signal
          controller.abort()
        }
      },
      async onResponseError({ response, options }: FetchContext) {
        if (!options.retry) {
          return
        }

        const isInternalAuthEndpoint = /\/api\/session\/(authenticate|refresh|logout){1}/.test(response?.url || '')
        const isExternalAuthEndpoint = /\/api\/v\d+\/developer\/(authenticate|refresh|logout){1}/.test(response?.url || '')
        const isPageContentEndpoint = /\/api\/v\d+\/portal\/pages\/content/.test(response?.url || '')

        // If the response status is 401 and the request is not to an session/authentication endpoint
        if (response?.status === 401 && !isInternalAuthEndpoint && !isExternalAuthEndpoint) {
          // Attempt to refresh the session
          await attemptSessionRefresh()
        } else if (response?.status === 404) {
          // If the portal is public, no need to retry or attempt a refresh
          if (info.value?.is_public === true) {
            options.retry = 0
            return
          } else if (info.value?.is_public === false && session.value.authenticated) {
            // If the portal is not public, and the user was _already_ authenticated

            // Determine if the request is attempting to retrieve fallback page content
            const requestUrl = new URL(response.url)
            const pagePath = requestUrl.searchParams.get('path')

            // If the request is not to the page content endpoint
            // or if there is no `?path=` query parameter
            // then do not retry the request and do not attempt to refresh the session, and exit early
            if (!isPageContentEndpoint || !pagePath) {
              options.retry = 0
              return
            }

            // Attempt to refresh the session
            await attemptSessionRefresh()
          }
        }
      },
    }

    /**
     * Attempts to refresh the user's session.
     * If the refresh fails, it clears the cookie values, logs out the user, and redirects them to the login page.
     */
    const attemptSessionRefresh = async (): Promise<void> => {
      try {
        // Use eventFetch so cookies are automatically available
        await eventFetch('/api/session/refresh', {
          method: 'POST',
        })

        // Important: Set the authenticated session value to true (this will also refresh the portal config data)
        session.value.authenticated = true
      } catch (error: any) {
        // Clear all cookie values since the refresh failed
        cookiePortalAccessToken = ''
        cookiePortalRefreshToken = ''
        cookieAccess.value = null
        cookieRefresh.value = null

        // Store the current route in the cookie to redirect on login
        sessionStore.setLoginReturnPath(route.fullPath)

        await eventFetch('/api/session/logout', {
          method: 'POST',
        })
        // Important: Set the authenticated session value to false (this will also refresh the portal config data)
        session.value.authenticated = false

        // TODO: expose trace id in the UI on the login page? (or in the console)

        // If the user is not authenticated after refreshing the session, redirect the user to the login page
        if (!route.path.includes('/login')) {
          await navigateTo({
            path: '/login',
            hash: '',
          })
        }
      }
    }

    /**
     * Perform a fetch request with a cookie and updates the cookies based on the response.
     *
     * @param {H3Event} event - The H3Event object.
     * @param {string} url - The URL to fetch.
     * @param options - Additional options for the fetch request.
     * @returns {Promise<Response>} A Promise that resolves to the fetch response.
     */
    const fetchWithCookie = async (event: H3Event, url: string, options: any = {}): Promise<Response> => {
      // Get the response from the server endpoint
      const response = await event.fetch(url, options)
      // Parse the set-cookie header of the response
      const setCookieHeader = response?.headers?.getSetCookie()
      // Parse the set-cookie header into an array of objects since they are all in a single string
      const setcookieHeaderArray: Cookie[] = parseSetCookie(splitCookiesString(setCookieHeader))

      // Loop through the session cookie names and update the cookies according to the `set-cookie` header, if they are present
      for (const cookie of setcookieHeaderArray) {
        // Extract the new properties
        const { name, value, domain, path, expires, maxAge, httpOnly, sameSite, secure } = cookie

        appendResponseHeader(event, 'set-cookie', serialize(name, value, {
          domain,
          path,
          expires,
          maxAge,
          httpOnly,
          // Set SameSite to false on localhost (so that we can also set Secure to false for Safari cookie compatibility)
          sameSite: localPortalOrigin ? false : sameSite as (boolean | 'lax' | 'strict' | 'none' | undefined),
          // Set Secure to false on localhost (for Safari cookie compatibility)
          secure: localPortalOrigin ? false : secure,
        }))

        // Update the local cookiePortalAccessToken value if present
        if (name === portalAccessToken) {
          cookiePortalAccessToken = value
        }

        // Update the local cookiePortalRefreshToken value if present
        if (name === portalRefreshToken) {
          cookiePortalRefreshToken = value
        }
      }

      return response
    }

    return {
      provide: {
        fetchWithCookie,
        // Register SDKs, see https://nuxt-open-fetch.vercel.app/advanced/custom-client
        ...Object.entries(clients).reduce((acc, [name, options]) => {
          return {
            ...acc,
            [name]: createOpenFetch(localOptions => {
              const customClients: OpenFetchClientName[] = ['portalApi', 'portalApiDev']

              let mergedOptions = customClients.includes(name as OpenFetchClientName) ? defu(localOptions, portalApiOptions) : localOptions

              // Add original options
              mergedOptions = defu(mergedOptions, options)

              // Combine the onRequest functions
              mergedOptions.onRequest = async (ctx) => {
                ctx.options.headers = {
                  ...ctx.options.headers || {},
                }
                // Ensure all headers are bound to request
                ctx.options.headers = defu(mergedOptions.headers, ctx.options.headers) as Headers
                typeof portalApiOptions?.onRequest === 'function' ? await portalApiOptions.onRequest(ctx) : null
                typeof localOptions?.onRequest === 'function' ? await localOptions.onRequest(ctx) : null
              }
              mergedOptions.onRequestError = async (ctx) => {
                typeof portalApiOptions?.onRequestError === 'function' ? await portalApiOptions.onRequestError(ctx) : null
                typeof localOptions?.onRequestError === 'function' ? await localOptions.onRequestError(ctx) : null
              }
              // Combine the onResponse functions
              mergedOptions.onResponse = async (ctx) => {
                typeof portalApiOptions?.onResponse === 'function' ? await portalApiOptions.onResponse(ctx) : null
                typeof localOptions?.onResponse === 'function' ? await localOptions.onResponse(ctx) : null
              }
              // !Important: Allows defining local response error options (e.g. ignoring 404 errors)
              mergedOptions.onResponseError = async (ctx) => {
                typeof portalApiOptions?.onResponseError === 'function' ? await portalApiOptions.onResponseError(ctx) : null
                typeof localOptions?.onResponseError === 'function' ? await localOptions.onResponseError(ctx) : null
              }

              // Return merged options
              return mergedOptions
            }, localFetch),
          }
        }, {}),
      },
    }
  },
})
