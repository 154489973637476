import { useEventListener } from '@vueuse/core'

export default defineNuxtPlugin({
  name: 'preview-mode',
  async setup() {
    // Never run the plugin on the server
    if (!import.meta.client) {
      return
    }

    const { enabled: previewModeEnabled, state, addPage } = usePortalPreviewMode()

    useEventListener(window, 'message', (evt: MessageEvent<PostPortalStudioMessageData>) => {
      // If preview mode is not enabled,
      // or event is not from Konnect, the same origin, or `localhost`, ignore the message
      if (!previewModeEnabled.value || !['konghq.com', 'konghq.tech', 'localhost'].some((origin: string) => evt.origin.includes(origin))) {
        return
      }

      const { preview_id, path, action, content } = evt.data

      // If exiting preview mode, disable it and exit early if the preview_id matches.
      // The `usePortalPreviewMode` composable will handle the rest.
      if (action === 'portal:preview:exit' && preview_id === state.value.preview_id) {
        previewModeEnabled.value = false
        return
      }

      // If the message action is not `portal:preview:update`, ignore the message
      if (action !== 'portal:preview:update') {
        return
      }

      // If the message preview_id is not a valid UUID, or not the correct preview_id, ignore the message
      if (!isValidUUID(preview_id) || (!!state.value.preview_id && state.value.preview_id !== preview_id)) {
        console.error('Invalid preview `preview_id`', preview_id)
        return
      }

      if (!path || path.startsWith('http')) {
        console.error('Invalid preview `path`', path)
        return
      }

      // If the message path content is not a string, ignore the message
      if (typeof content !== 'string') {
        console.error('Invalid preview `content`', content)
        return
      }

      // Store the new page content
      addPage(path, content)
    })
  },
})
