// Import all of the `@kong/design-tokens` CSS Custom Properties as a inline (raw) string to make them available within custom pages and the rest of the app
import kongDesignTokensCssCustomProperties from '@kong/design-tokens/tokens/css/custom-properties.css?inline'

/**
 * Customize the Portal theme colors based on the response from the `/portal/appearance` endpoint.
 */
export default defineNuxtPlugin({
  name: 'customization',
  dependsOn: ['fetch-portal-data'],
  parallel: true,
  async setup() {
    const { customization, appearance } = storeToRefs(usePortalStore())
    const cssCustomProperties: string[] = []

    // Get font families from the appearance object
    const { base: fontText, code: fontCode, headings: fontHeading } = (appearance.value?.stylesheets?.global?.main?.data as Record<string, any>)?.font || {}
    // Push font families to the cssCustomProperties array
    cssCustomProperties.push(fontText && `--kui-font-family-text: ${fontText}, sans-serif;`)
    cssCustomProperties.push(fontCode && `--kui-font-family-code: ${fontCode}, sans-serif;`)
    cssCustomProperties.push(fontHeading && `--kui-font-family-heading: ${fontHeading}, sans-serif;`)

    // Ensure cssCustomProperties are formatted correctly for the :root ruleset
    const rootStyles: string = cssCustomProperties
      // Remove empty values
      .filter(rule => String(rule || '').trim().length > 0)
      // Add a semicolon to the end of each rule
      .map(rule => rule.endsWith(';') ? rule : `${rule};`)
      // Join all rules together into a string
      .join('') || ''

    // Set the customPortalCss variable to the css property from the customization object
    const customPortalCss = customization.value?.data?.css || ''

    // Customize the portal based on the response from the `/portal/appearance` endpoint (and other future customizations).
    // Tag priority is used to determine the order in which the tags are added to the head element.
    useHead({
      style: [
        // Load the `@kong/design-tokens` CSS Custom Properties to make them available within custom pages and the rest of the app
        {
          key: 'kong-design-tokens-css-custom-properties',
          // When using innerHTML the content will not be sanitised. Client side it will use the element.innerHTML setter. Make sure you sanitise user input if providing it with this property.
          // !Important: Use innerHTML here to ensure the CSS Custom Properties are _immediately_ available to the rest of the app
          innerHTML: parseCustomCss(kongDesignTokensCssCustomProperties),
          tagPosition: 'head',
          tagPriority: 0,
        },
        // Customizations provided by `/portal/appearance` (processed above)
        rootStyles.length ? {
          key: 'portal-appearance-customizations',
          // Use textContent to apply simple sanitiszation server-side; client-side it will use the `element.textContent` setter
          textContent: parseCustomCss(wrapString(rootStyles, ':root{', '}')),
          tagPosition: 'head',
          tagPriority: 1 } : {},
        // Custom CSS provided by the `/portal/customization` endpoint; injected to the top of the body element to ensure it's applied last.
        customPortalCss.length ? {
          key: 'portal-custom-css',
          'data-testid': 'portal-custom-css',
          // Use textContent to apply simple sanitiszation server-side; client-side it will use the `element.textContent` setter
          textContent: parseCustomCss(customPortalCss),
          tagPosition: 'bodyOpen',
          tagPriority: 0,
        } : {},
      ],
    })
  },
})
