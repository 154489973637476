export default defineNuxtPlugin({
  name: 'fetch-portal-data',
  async setup(nuxtApp) {
    // Since this is the first plugin, we can enable performance tracing
    nuxtApp.vueApp.config.performance = true

    // Fetch portal data
    await usePortalStore().refreshPortalConfigs()
  },
})
