import { default as _91_46_46_46page_slug_93rvJNAKG9psMeta } from "/work/portal/portal/layers/core/pages/[...page_slug].vue?macro=true";
import { default as analytics5WaDdGfIi0Meta } from "/work/portal/portal/layers/core/pages/apps/[app_id]/analytics.vue?macro=true";
import { default as apisLyvH9LPl3RMeta } from "/work/portal/portal/layers/core/pages/apps/[app_id]/apis.vue?macro=true";
import { default as credentialsP1BirJ1Rh0Meta } from "/work/portal/portal/layers/core/pages/apps/[app_id]/credentials.vue?macro=true";
import { default as _91app_id_933hLIZXiT8iMeta } from "/work/portal/portal/layers/core/pages/apps/[app_id].vue?macro=true";
import { default as indexWYq4nAUrecMeta } from "/work/portal/portal/layers/core/pages/apps/index.vue?macro=true";
import { default as forgot_45passwordHNMgB2IYyvMeta } from "/work/portal/portal/layers/core/pages/forgot-password.vue?macro=true";
import { default as _91_46_46_46login_path_93TOr50nFUy2Meta } from "/work/portal/portal/layers/core/pages/login/[...login_path].vue?macro=true";
import { default as logoutNaiLbrKTG1Meta } from "/work/portal/portal/layers/core/pages/logout.vue?macro=true";
import { default as _91document_slug_932bcJcuCXYCMeta } from "/work/portal/portal/layers/core/pages/products/[product_id]/docs/[document_slug].vue?macro=true";
import { default as index1aENM6qOhjMeta } from "/work/portal/portal/layers/core/pages/products/[product_id]/docs/index.vue?macro=true";
import { default as index0RfOaWhaBqMeta } from "/work/portal/portal/layers/core/pages/products/[product_id]/versions/[version_id]/index.vue?macro=true";
import { default as _91_46_46_46spec_path_93zboSL1WNrHMeta } from "/work/portal/portal/layers/core/pages/products/[product_id]/versions/[version_id]/specs/[...spec_path].vue?macro=true";
import { default as indexbbPHaWXISpMeta } from "/work/portal/portal/layers/core/pages/products/[product_id]/versions/index.vue?macro=true";
import { default as _91product_id_93RpTZnOS9SBMeta } from "/work/portal/portal/layers/core/pages/products/[product_id].vue?macro=true";
import { default as registers98cKDjXmlMeta } from "/work/portal/portal/layers/core/pages/register.vue?macro=true";
import { default as reset_45passwordsJzNHZmylmMeta } from "/work/portal/portal/layers/core/pages/reset-password.vue?macro=true";
import { default as component_45stubp7CfKGZ7t6Meta } from "/work/portal/portal/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.3.3__ioredis@5.4.1__ebnisdrh3kwfxfl4quj4naecoi/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubp7CfKGZ7t6 } from "/work/portal/portal/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.3.3__ioredis@5.4.1__ebnisdrh3kwfxfl4quj4naecoi/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "page_slug",
    path: "/:page_slug(.*)*",
    component: () => import("/work/portal/portal/layers/core/pages/[...page_slug].vue")
  },
  {
    name: "apps-app_id",
    path: "/apps/:app_id()",
    component: () => import("/work/portal/portal/layers/core/pages/apps/[app_id].vue"),
    children: [
  {
    name: "apps-app_id-analytics",
    path: "analytics",
    component: () => import("/work/portal/portal/layers/core/pages/apps/[app_id]/analytics.vue")
  },
  {
    name: "apps-app_id-apis",
    path: "apis",
    component: () => import("/work/portal/portal/layers/core/pages/apps/[app_id]/apis.vue")
  },
  {
    name: "apps-app_id-credentials",
    path: "credentials",
    component: () => import("/work/portal/portal/layers/core/pages/apps/[app_id]/credentials.vue")
  }
]
  },
  {
    name: "apps",
    path: "/apps",
    component: () => import("/work/portal/portal/layers/core/pages/apps/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/work/portal/portal/layers/core/pages/forgot-password.vue")
  },
  {
    name: "login-login_path",
    path: "/login/:login_path(.*)*",
    component: () => import("/work/portal/portal/layers/core/pages/login/[...login_path].vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/work/portal/portal/layers/core/pages/logout.vue")
  },
  {
    name: "products-product_id",
    path: "/products/:product_id()",
    meta: _91product_id_93RpTZnOS9SBMeta || {},
    component: () => import("/work/portal/portal/layers/core/pages/products/[product_id].vue"),
    children: [
  {
    name: "products-product_id-docs-document_slug",
    path: "docs/:document_slug()",
    meta: _91document_slug_932bcJcuCXYCMeta || {},
    component: () => import("/work/portal/portal/layers/core/pages/products/[product_id]/docs/[document_slug].vue")
  },
  {
    name: "products-product_id-docs",
    path: "docs",
    meta: index1aENM6qOhjMeta || {},
    component: () => import("/work/portal/portal/layers/core/pages/products/[product_id]/docs/index.vue")
  },
  {
    name: "products-product_id-versions-version_id",
    path: "versions/:version_id()",
    component: () => import("/work/portal/portal/layers/core/pages/products/[product_id]/versions/[version_id]/index.vue")
  },
  {
    name: "products-product_id-versions-version_id-specs-spec_path",
    path: "versions/:version_id()/specs/:spec_path(.*)*",
    component: () => import("/work/portal/portal/layers/core/pages/products/[product_id]/versions/[version_id]/specs/[...spec_path].vue")
  },
  {
    name: "products-product_id-versions",
    path: "versions",
    component: () => import("/work/portal/portal/layers/core/pages/products/[product_id]/versions/index.vue")
  }
]
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/work/portal/portal/layers/core/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/work/portal/portal/layers/core/pages/reset-password.vue")
  },
  {
    name: component_45stubp7CfKGZ7t6Meta?.name,
    path: "/my-apps/:pathMatch(.*)",
    component: component_45stubp7CfKGZ7t6
  },
  {
    name: component_45stubp7CfKGZ7t6Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubp7CfKGZ7t6
  },
  {
    name: component_45stubp7CfKGZ7t6Meta?.name,
    path: "/pages-sitemap.xml",
    component: component_45stubp7CfKGZ7t6
  },
  {
    name: component_45stubp7CfKGZ7t6Meta?.name,
    path: "/apis-sitemap.xml",
    component: component_45stubp7CfKGZ7t6
  }
]