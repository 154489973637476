import { COOKIE_PREVIEW_MODE, COOKIE_PREVIEW_ID } from '../constants/preview-mode'

/** The data for the active Portal Preview ID */
interface PreviewData {
  /** Portal Page preview content, stored in a Map with the format Map<path, content> */
  pages?: Map<string, string>
}

interface PreviewState {
  preview_id?: string
  data: PreviewData
}

let unregisterRefreshHook: (() => any) | undefined
const previewKey = 'portal-preview-mode'

export default function usePortalPreviewMode() {
  const enabled = useState<boolean>(`${previewKey}-enabled`, () => false)
  const state = useState<PreviewState>(`${previewKey}-state`, () => ({
    data: {},
  }))

  // Init preview cookies
  const previewCookie = useCookie(COOKIE_PREVIEW_MODE)
  const previewIdCookie = useCookie(COOKIE_PREVIEW_ID)

  if (!enabled.value) {
    enabled.value = !!previewCookie.value && String(previewCookie.value) === 'true' && !!previewIdCookie.value && isValidUUID(previewIdCookie.value)
  }

  watch(enabled, async (isEnabled) => {
    if (isEnabled) {
      const newState: PreviewState = {
        preview_id: previewIdCookie.value || undefined,
        data: state.value.data,
      }

      // Update the preview state if it differs from the current state
      if (JSON.stringify(newState) !== JSON.stringify(state.value)) {
        state.value = Object.assign(state.value, newState)
      }

      // Register a hook to refresh Nuxt data when navigating after the preview mode is disabled
      if (import.meta.client && !unregisterRefreshHook) {
        unregisterRefreshHook = async () => {
          // Clear the state data
          state.value.preview_id = undefined
          state.value.data = {}

          // Clear the preview mode cookies
          previewCookie.value = null
          previewIdCookie.value = null

          // Clear the preview mode query params if they exist
          if (useRoute().query.preview || useRoute().query.preview_id) {
            await useRouter().replace({
              query: {
                // Keep other query params
                ...useRoute().query,
                // Remove preview mode query params
                ...{ preview: undefined, preview_id: undefined, ref: undefined },
              },
            })
          }

          clearNuxtData(`${previewKey}-enabled`)
          clearNuxtData(`${previewKey}-state`)
          reloadNuxtApp({
            persistState: false,
            // Append the `preview=false` query param to the current route path to force-disable preview mode
            // Append a timestamp ref query param to the current route path for cache-busting
            path: `${useRoute().path}?preview=false&ref=${Date.now().toString()}`,
          })
        }
      }
    } else if (isEnabled === false && unregisterRefreshHook) {
      // Call the hook
      await unregisterRefreshHook()
      // Clear the hook
      unregisterRefreshHook = undefined
    }
  }, { immediate: true, flush: 'sync' })

  /**
   * Adds a new page to the portal preview mode.
   *
   * @param {string} path - The path of the page to be added.
   * @param {string} content - The raw markdown content of the page to be added.
   * @returns void
   */
  const addPage = (path: string, content: string): void => {
    // Store the new content
    state.value.data.pages = state.value.data.pages || new Map<string, string>()

    const pagePath = addLeadingSlash(path)
    // Only update the page if it doesn't exist or the content has changed
    if (!state.value.data.pages.has(pagePath) || state.value.data.pages.get(pagePath) !== content) {
      state.value.data.pages.set(addLeadingSlash(path), content)
    }
  }

  return {
    enabled,
    state,
    // methods
    addPage,
  }
}
