/**
 * Initialize Launch Darkly SDKs and identify the user.
 */
export default defineNuxtPlugin({
  name: 'launch-darkly',
  dependsOn: ['fetch-portal-data'],
  async setup() {
    // Initialize Launch Darkly
    await useLaunchDarkly().initialize()
  },
})
