export const usePortalStore = defineStore('portal', () => {
  /** The Portal info data from /api/v[\d+]/portal */
  const info = ref<PortalContext>()
  /** The exposed Konnect Portal config from /api/v[\d+]/portal/config */
  const config = ref<PortalConfig>()
  /** The Portal customization data from /api/v[\d+]/portal/customization */
  const customization = ref<PortalCustomization>()
  /** The Portal appearance data from /api/v[\d+]/portal/appearance */
  const appearance = ref<PortalAppearance>()
  // Get the cookie headers
  const cookieHeaders = useRequestHeaders(['cookie'])
  // Import session data
  const { session } = storeToRefs(useSessionStore())
  const { portalAccessToken, portalRefreshToken } = useRuntimeConfig().public.cookies
  const cookieAccess = useCookie(portalAccessToken)
  const cookieRefresh = useCookie(portalRefreshToken)

  /**
   * Fetches the portal info
   */
  const fetchPortalInfo = async (): Promise<void> => {
    try {
      info.value = await $fetch('/api/v2/portal', {
        headers: session.value.authenticated /* && cookieAccess.value */
          ? cookieHeaders
          : {
            Cookie: '',
          },
      })

      // If the Portal is public (or public status cannot be determined), clear the portalAccessToken and portalRefreshToken
      if (info.value?.is_public !== false) {
        cookieAccess.value = null
        cookieRefresh.value = null
        session.value.authenticated = false
      }

      if (info.value?.is_public === undefined) {
        // is_public property is undefined, show global error for now
        throw createError({
          // TODO: expose trace id in the UI
          statusCode: 500,
          statusMessage: $t('errors.portal_config.public_or_private'),
          fatal: true,
        })
      }
    } catch (error: any) {
      console.log('fetchPortalInfo error', error.statusCode)

      // Clear the auth cookies if 401
      if (error.statusCode === 401 || error.status === 401) {
        cookieAccess.value = null
        cookieRefresh.value = null
        session.value.authenticated = false
      }

      throw createError({
        // TODO: expose trace id in the UI
        statusCode: error.status || error.response?.status || 500,
        statusMessage: error.statusMessage || $t('errors.portal_config.info'),
        fatal: true,
      })
    }
  }

  /**
   * Fetches the portal config
   */
  const fetchPortalConfig = async (): Promise<void> => {
    try {
      config.value = await $fetch('/api/v2/portal/config', {
        headers: session.value.authenticated
          ? cookieHeaders
          : {
            Cookie: '',
          },
      })

      // If stored config is empty, utilize the mock config.
      // TODO: Remove this fallback mock once the endpoint is available for all orgs
      if (!config.value?.data || Object.keys(config.value).length === 0 || Object.keys(config.value?.data).length === 0) {
        // TODO: for now, if the config endpoint throws an error (or 404), fetch the mock config
        await fetchMockPortalConfig()
      }
    } catch (error: any) {
      // TODO: for now, if the config endpoint throws an error (or 404), fetch the mock config
      await fetchMockPortalConfig()
    }
  }

  /**
   * Fetches the portal customization
   */
  const fetchPortalCustomization = async (): Promise<void> => {
    try {
      customization.value = await $fetch('/api/v2/portal/customization', {
        headers: session.value.authenticated /* && cookieAccess.value */
          ? cookieHeaders
          : {
            Cookie: '',
          },
      })
    } catch (error: any) {
      console.log('customization error', error.statusCode)
      throw createError({
        // TODO: expose trace id in the UI
        statusCode: error.status || error.response?.status || 500,
        statusMessage: error.statusMessage || $t('errors.portal_config.customization'),
        fatal: true,
      })
    }
  }

  /**
   * Fetches the portal appearance
   */
  const fetchPortalAppearance = async (): Promise<void> => {
    try {
      appearance.value = await $fetch('/api/v2/portal/appearance', {
        headers: session.value.authenticated /* && cookieAccess.value */
          ? cookieHeaders
          : {
            Cookie: '',
          },
      })
    } catch (error: any) {
      console.log('fetchPortalAppearance error', error.statusCode)
      throw createError({
        // TODO: expose trace id in the UI
        statusCode: error.status || error.response?.status || 500,
        statusMessage: error.statusMessage || $t('errors.portal_config.appearance'),
        fatal: true,
      })
    }
  }

  /** Fetch/refresh all Portal config data */
  const refreshPortalConfigs = async (): Promise<void> => {
    try {
      // Fetch portal info first to determine public/private portal
      await fetchPortalInfo()

      // Then fetch remaining config endpoints
      await Promise.all([
        fetchPortalConfig(),
        fetchPortalCustomization(),
        fetchPortalAppearance(),
      ])
    } catch {
      // no-op
    }
  }

  /** Fetch a mocked config endpoint */
  const fetchMockPortalConfig = async (): Promise<void> => {
    console.warn('portal-config: mocking config response for this portal since it does not exist')
    // Fetch from mocked config endpoint
    config.value = await $fetch('/mock-api/portal/config', {
      query: {
        // Pass the `public: boolean` query param to the mock endpoint to modify the stubbed data
        public: info.value?.is_public,
      },
    })
  }

  return {
    info,
    config,
    customization,
    appearance,
    // Methods
    refreshPortalConfigs,
  }
})
