<template>
  <div class="app-container">
    <NuxtLoadingIndicator :height="2" />
    <NuxtPage />
  </div>
</template>

<script setup lang="ts">
const { info, config, appearance } = storeToRefs(usePortalStore())
// @ts-ignore: property exists if defined
const themeColor = appearance.value?.stylesheets?.global?.main?.data?.colors?.['text_colors-accent']
const siteLogo = computed((): string | undefined => {
  if (appearance.value?.variables?.catalog?.logo?.url) {
    return `/api${appearance.value.variables.catalog.logo.url}`
  }

  return undefined
})

useHead({
  htmlAttrs: {
    // Add a `data-portal-theme` attribute to the root <html> element
    'data-portal-theme': `${config.value?.data?.theme || 'ocean'}`,
  },
})

useSchemaOrg([
  defineWebSite({
    name: info.value?.name,
  }),
])

defineOgImage({
  component: 'NuxtSeo',
  props: {
    theme: themeColor,
    siteLogo: siteLogo.value,
  },
})
</script>

<style lang="scss" scoped>
.app-container {
  &,
  // The <NuxtPage /> component adds a div that must have 100% height as well; don't add a class to the <NuxtPage /> component
  :deep(> div:not(.nuxt-loading-indicator)) {
    height: 100%;
    min-height: 100%; // Important - include min-height for the sticky positioning of elements
  }

  :deep(.nuxt-loading-indicator) {
    background: var(--kui-color-background-primary-weak, $kui-color-background-primary-weak) !important;
  }
}
</style>
