import { defineNuxtPlugin } from '#app/nuxt'
import { LazyApisList, LazyCodeTabs, LazyCopyButton, LazyEmptyState, LazyGridItem, LazyAccordionGroup, LazyAccordionPanel, LazyMultiColumn, LazyPageContainer, LazyPageHero, LazyPageSection, LazyProseIframe, LazyProseImg, LazyProsePre, LazyProseTable, LazyProseA, LazyProseBlockquote, LazyProseCode, LazyProseEm, LazyProseH1, LazyProseH2, LazyProseH3, LazyProseH4, LazyProseH5, LazyProseH6, LazyProseHr, LazyProseLi, LazyProseOl, LazyProseP, LazyProseScript, LazyProseStrong, LazyProseTbody, LazyProseTd, LazyProseTh, LazyProseThead, LazyProseTr, LazyProseUl } from '#components'
const lazyGlobalComponents = [
  ["ApisList", LazyApisList],
["CodeTabs", LazyCodeTabs],
["CopyButton", LazyCopyButton],
["EmptyState", LazyEmptyState],
["GridItem", LazyGridItem],
["AccordionGroup", LazyAccordionGroup],
["AccordionPanel", LazyAccordionPanel],
["MultiColumn", LazyMultiColumn],
["PageContainer", LazyPageContainer],
["PageHero", LazyPageHero],
["PageSection", LazyPageSection],
["ProseIframe", LazyProseIframe],
["ProseImg", LazyProseImg],
["ProsePre", LazyProsePre],
["ProseTable", LazyProseTable],
["ProseA", LazyProseA],
["ProseBlockquote", LazyProseBlockquote],
["ProseCode", LazyProseCode],
["ProseEm", LazyProseEm],
["ProseH1", LazyProseH1],
["ProseH2", LazyProseH2],
["ProseH3", LazyProseH3],
["ProseH4", LazyProseH4],
["ProseH5", LazyProseH5],
["ProseH6", LazyProseH6],
["ProseHr", LazyProseHr],
["ProseLi", LazyProseLi],
["ProseOl", LazyProseOl],
["ProseP", LazyProseP],
["ProseScript", LazyProseScript],
["ProseStrong", LazyProseStrong],
["ProseTbody", LazyProseTbody],
["ProseTd", LazyProseTd],
["ProseTh", LazyProseTh],
["ProseThead", LazyProseThead],
["ProseTr", LazyProseTr],
["ProseUl", LazyProseUl],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
