<template>
  <LayoutBase class="error">
    <template #default>
      <LayoutBaseContent class="error-base-content">
        <PageSection vertical-align="center">
          <KCard class="error-content-container">
            <div class="status-code">
              {{ error.statusCode }}
            </div>
            <h1 v-if="error.statusMessage">
              {{ error.statusMessage }}
            </h1>
            <div class="actions">
              <i18n-t
                v-if="showPrivatePortal404"
                class="form-footer-text"
                keypath="errors.log_in_or_sign_up"
                scope="global"
                tag="p"
              >
                <template #log_in>
                  <NuxtLink :href="{ name: 'login-login_path' }">
                    {{ t('errors.log_in_link') }}
                  </NuxtLink>
                </template>
                <template #sign_up>
                  <NuxtLink :href="{ name: 'register' }">
                    {{ t('errors.sign_up_link') }}
                  </NuxtLink>
                </template>
              </i18n-t>
              <div>
                <KButton
                  v-if="showHomeLink"
                  appearance="secondary"
                  size="large"
                  @click="clearError({ redirect: info?.is_public === false && !session.authenticated ? '/login' : '/' })"
                >
                  {{ t('actions.return_to_page', { page: info?.is_public === false && !session.authenticated ? t('pages.login.title') : t('pages.home.title') }) }}
                </KButton>
              </div>
            </div>
          </KCard>
        </PageSection>
      </LayoutBaseContent>
    </template>
  </LayoutBase>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'
import { KButton } from '@kong/kongponents'

const props = defineProps({
  error: {
    type: Object as () => NuxtError,
    default: () => null,
  },
})

const { info } = storeToRefs(usePortalStore())
const sessionStore = useSessionStore()
const { session } = storeToRefs(sessionStore)
const route = useRoute()
const { t } = useI18n()

const showPrivatePortal404 = computed((): boolean => info.value?.is_public === false && props.error.statusCode === 404 && !session.value.authenticated)

// If the user arrived to the page via a 404, and the portal is private, and they are not authenticated, store the return path
if (showPrivatePortal404.value) {
  sessionStore.setLoginReturnPath(route.fullPath)
}

// Determine whether to show the home link based on the error statusCode
const showHomeLink = computed((): boolean => !showPrivatePortal404.value && !session.value.authenticated && props.error?.statusCode !== 401 && (props.error?.statusCode !== undefined && !props.error?.statusCode?.toString()?.startsWith('5')))
</script>

<style lang="scss" scoped>
:deep(.layout-content-container) {
  display: flex;
}

.error-content-container {
  display: flex;
  flex-direction: column;
  gap: var(--kui-space-70, $kui-space-70);
  justify-content: center;
  margin: var(--kui-space-0, $kui-space-0) var(--kui-space-auto, $kui-space-auto);
  max-width: 600px;
  text-align: center;

  .status-code {
    color: var(--kui-color-text-neutral-weak, $kui-color-text-neutral-weak);
    font-size: var(--kui-font-size-100, $kui-font-size-100);
    font-weight: var(--kui-font-weight-semibold, $kui-font-weight-semibold);
    line-height: var(--kui-line-height-100, $kui-line-height-100);
    margin-bottom: var(--kui-space-70, $kui-space-70);
    user-select: none;
  }

  h1 {
    font-size: var(--kui-font-size-80, $kui-font-size-80);
    line-height: var(--kui-line-height-80, $kui-line-height-80);
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: var(--kui-space-40, $kui-space-40);
    width: 100%;
  }
}
</style>
