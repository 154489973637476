<template>
  <div
    class="layout-base"
    :data-path="route.path"
  >
    <LayoutPageOverlay
      v-if="showPageOverlay"
      @click="onOverlayClose"
    />
    <LayoutHeader v-if="!hideHeader" />
    <div
      class="global-page"
      :class="[{ 'no-header': hideHeader }]"
    >
      <slot name="sidebar" />

      <div class="global-content">
        <main class="global-main">
          <slot name="default" />
        </main>
        <LayoutFooter />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const layoutStore = useLayoutStore()
const { showPageOverlay } = storeToRefs(layoutStore)
const route = useRoute()
const globalError = useError()

const onOverlayClose = (): void => {
  layoutStore.closeAllSidebars()
}

// Hide the LayoutHeader component on specific routes unless a globalError is being shown
const hideHeader = computed((): boolean => {
  if (
    !globalError.value &&
    (route.name === 'login-login_path'
    || route.name === 'forgot-password'
    || route.name === 'reset-password'
    || route.name === 'register'
    || route.name === 'logout')
  ) {
    return true
  }

  return false
})

// Hide the document.body overflow when the global page overlay is active
useHeadSafe({
  bodyAttrs: {
    class: computed(() => showPageOverlay.value ? 'overflow-hidden' : ''),
  },
})
</script>

<style lang="scss" scoped>
.layout-base {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100%;
  width: 100%;
}

.global-page,
.global-content,
main.global-main {
  display: flex;
  flex: 1 1 auto; // expand to fill space
  min-width: 0; // Important: By explicitly setting min-width: 0; on the flex item, we can override the default behavior and allow the element to shrink beyond its automatic minimum size.
  width: 100%;
}

.global-page {
  flex-direction: row;
  min-height: calc(100vh - $header-height); // Ensure the page is at least as tall as the viewport minus the header height

  &.no-header {
    min-height: 100vh; // Ensure the page is at least as tall as the viewport
  }
}

.global-content {
  flex-direction: column;
}

main.global-main {
  flex-direction: column;
  min-height: 500px; // Ensure the main content is at least 500px in height

  :deep(> div:not(.layout-page-header)) {
    height: 100%;
  }
}
</style>
