<template>
  <div
    v-if="footerSectionMenuItems?.length"
    class="layout-menu-footer-sections"
  >
    <div class="footer-sections-content">
      <PageContainer
        class="footer-sections-container"
        display="grid"
        :grid-columns-breakpoints="{
          desktop: footerSectionMenuItems.length > 4 ? 5 : footerSectionMenuItems.length,
          mobile: footerSectionMenuItems.length > 1 ? 2 : footerSectionMenuItems.length,
          phablet: footerSectionMenuItems.length > 2 ? 3 : footerSectionMenuItems.length,
          tablet: footerSectionMenuItems.length > 3 ? 4 : footerSectionMenuItems.length
        }"
      >
        <div
          v-for="(footerSection, idx) of footerSectionMenuItems"
          :key="footerSection.title || `footer-section-${idx}`"
        >
          <div
            v-if="footerSection.title"
            class="footer-section-title"
          >
            {{ footerSection.title }}
          </div>
          <nav v-if="footerSection.items?.length">
            <ul>
              <li
                v-for="link of footerSection.items"
                :key="link.path"
              >
                <NuxtLink
                  :external="link.external"
                  :href="link.path"
                  :target="link.external ? '_blank' : '_self'"
                >
                  {{ link.title }}
                </NuxtLink>
              </li>
            </ul>
          </nav>
        </div>
      </PageContainer>
    </div>
  </div>
</template>

<script setup lang="ts">
const footerSectionMenuItems = useNavMenu<PortalFooterMenuSection>('footer_sections')
</script>

<style lang="scss" scoped>
.layout-menu-footer-sections {
  background-color: var(--kui-color-background, $kui-color-background);
  width: 100%;

  .footer-sections-content {
    margin: 0 auto;
    max-width: var(--kui-breakpoint-laptop, $kui-breakpoint-laptop);
    padding: var(--kui-space-70, $kui-space-70);
    width: 100%;
  }

  ul {
    list-style: none;
    margin: var(--kui-space-0, $kui-space-0);
    padding: var(--kui-space-0, $kui-space-0);

    li {
      display: block;
      margin-bottom: var(--kui-space-30, $kui-space-30);
    }

    a {
      color: var(--kui-color-text-neutral, $kui-color-text-neutral);
      display: inline-block;
      font-size: var(--kui-font-size-30, $kui-font-size-30);
      text-decoration: none;
      transition: color 0.2s ease-in-out;
      user-select: none;

      &:hover {
        color: var(--kui-color-text-neutral-strong, $kui-color-text-neutral-strong);
      }

      // Hide the external link icon
      &[target="_blank"] {
        background-image: none !important;
        padding-right: var(--kui-space-0, $kui-space-0);
      }
    }
  }
}

.footer-section-title {
  color: var(--kui-color-text-neutral-strong, $kui-color-text-neutral-strong);
  font-weight: var(--kui-font-weight-medium, $kui-font-weight-medium);
  margin-bottom: var(--kui-space-30, $kui-space-30);
  user-select: none;
}
</style>
