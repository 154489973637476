<template>
  <button
    data-testid="close-button"
    type="button"
  >
    <CloseIcon
      data-testid="close-button-icon"
      :size="size"
      :title="t('actions.close')"
    />
  </button>
</template>

<script setup lang="ts">
import { CloseIcon } from '@kong/icons'
import { KUI_ICON_SIZE_50 } from '@kong/design-tokens'

defineProps({
  /** The icon size, in pixels. Defaults to 24px. */
  size: {
    type: String,
    default: KUI_ICON_SIZE_50,
  },
})
const { t } = useI18n()
</script>

<style lang="scss" scoped>
button {
  align-items: center;
  background-color: var(--kui-color-background-transparent, $kui-color-background-transparent);
  border: none;
  color: var(--kui-color-text-neutral-weak, $kui-color-text-neutral-weak);
  cursor: pointer;
  display: inline-flex;
  gap: var(--kui-space-20, $kui-space-20);
  justify-content: center;
  justify-self: flex-end;
  padding: var(--kui-space-0, $kui-space-0);
}
</style>
