<template>
  <div>
    <button
      class="search-button"
      type="button"
      @click.stop="modalVisible = !modalVisible"
    >
      <SearchIcon :size="KUI_ICON_SIZE_50" />

      <span class="search-text">
        {{ t('actions.search') }}
      </span>
      <span
        class="search-shortcut"
        :class="{ 'mac': isMac || isMac === undefined }"
      >
        <kbd
          v-if="isMac === undefined"
          class="search-command"
        />
        <SearchCommand
          @mac="(mac: boolean) => isMac = mac"
          @search="onSearchShortcut"
        />
        <kbd>K</kbd>
      </span>
    </button>

    <SearchModal
      :visible="modalVisible"
      @close="onModalClosed"
    />
  </div>
</template>

<script setup lang="ts">
import { SearchIcon } from '@kong/icons'
import { KUI_ICON_SIZE_50 } from '@kong/design-tokens'

const { t } = useI18n()

// Initialize as undefined in order to prevent the flash of the client component
const isMac = useState<boolean | undefined>(() => undefined)
const modalVisible = useState<boolean>(() => false)
const onSearchShortcut = (): void => {
  // If the modal is not visible and user presses Cmd/Ctrl+K, open the search modal
  if (!modalVisible.value) {
    modalVisible.value = true
  }
}
const onModalClosed = async (): Promise<void> => {
  await nextTick()
  modalVisible.value = false
}
</script>

<style lang="scss" scoped>
.search-button {
  align-items: center;
  // No background color on mobile
  background-color: var(--kui-color-background-transparent, $kui-color-background-transparent);
  border: 0;
  border-radius: var(--kui-border-radius-40, $kui-border-radius-40);
  color: var(--kui-color-text-inverse, $kui-color-text-inverse);
  cursor: pointer;
  display: flex;
  font-size: var(--kui-font-size-30, $kui-font-size-30);
  gap: var(--kui-space-40, $kui-space-40);
  padding: var(--kui-space-20, $kui-space-20);
  transition: background-color 0.2s ease-in-out;
  user-select: none;

  @media (min-width: $kui-breakpoint-tablet) {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: var(--kui-border-radius-40, $kui-border-radius-40);
    padding: var(--kui-space-30, $kui-space-30);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  .search-text {
    display: none;
    line-height: 1;
    padding-right: var(--kui-space-30, $kui-space-30);

    // We only display the icon on mobile
    @media (min-width: $kui-breakpoint-tablet) {
      display: block;
    }
  }

  .search-shortcut {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.20);
    border-radius: var(--kui-border-radius-20, $kui-border-radius-20);
    display: none; // Hide keyboard shortcut on mobile
    gap: var(--kui-space-30, $kui-space-30);
    line-height: 1;
    padding: var(--kui-space-20, $kui-space-20) var(--kui-space-30, $kui-space-30);

    @media (min-width: $kui-breakpoint-tablet) {
      display: flex;
    }

    :deep(.search-command:before),
    kbd {
      font-family: var(--kui-font-family-text, $kui-font-family-text);
      font-size: var(--kui-font-size-30, $kui-font-size-30);
    }

    :deep(.search-command:before) {
      content: 'Ctrl';
    }

    &.mac {
      gap: var(--kui-space-10, $kui-space-10);

      :deep(.search-command:before) {
        content: '\2318';
        line-height: 0;
        position: relative;
      }
    }
  }
}

.search-modal-content {
  padding: var(--kui-space-70, $kui-space-70);
}

.search-modal-footer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: var(--kui-space-70, $kui-space-70);
}
</style>
