<template>
  <nav
    v-if="headerMenuItems?.length"
    class="layout-menu-header"
    :class="{ 'mobile-menu': mobileMenu }"
  >
    <ul>
      <li
        v-for="link of headerMenuItems"
        :key="link.path"
      >
        <NuxtLink
          :class="{ active: checkIfActiveLink(link) }"
          :external="link.external"
          :href="link.path"
          prefetch
          :target="link.external ? '_blank' : '_self'"
        >
          {{ link.title }}
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
defineProps({
  mobileMenu: {
    type: Boolean,
    default: false,
  },
})

const headerMenuItems = useNavMenu<PortalMenuItem>('header')
const { appearance } = storeToRefs(usePortalStore())
const route = useRoute()
const linkColor = (appearance.value?.stylesheets?.global?.main?.data as Record<string, any>)?.colors?.['section_colors-body']

const checkIfActiveLink = (link: PortalMenuItem): boolean => {
  // Special case for the home page
  if (link.path === '/') {
    return route.path === '/'
  } else {
    return !link.external && route.path.startsWith(link.path)
  }
}
</script>

<style lang="scss" scoped>
.layout-menu-header {
  // Shared styles
  ul {
    display: flex;
    list-style: none;
    margin: var(--kui-space-0, $kui-space-0);
    padding: var(--kui-space-0, $kui-space-0);

    li, a {
      align-items: center;
      display: flex;
      margin-bottom: var(--kui-space-0, $kui-space-0);
    }
  }

  // Mobile menu styles
  &.mobile-menu {
    ul {
      flex-direction: column;

      li, a {
        width: 100%;
      }

      li {
        justify-content: flex-start;
        margin-bottom: var(--kui-space-70, $kui-space-70);
      }

      a {
        color: v-bind(linkColor);
        display: block;
        font-size: var(--kui-font-size-80, $kui-font-size-80);
        font-weight: var(--kui-font-weight-medium, $kui-font-weight-medium);
        overflow: hidden;
        padding: var(--kui-space-30, $kui-space-30) var(--kui-space-50, $kui-space-50);
        text-decoration: none;
        text-overflow: ellipsis;
        user-select: none;
        white-space: nowrap;

        // Hide the external link icon
        &[target="_blank"] {
          background-image: none !important;
          padding-right: var(--kui-space-0, $kui-space-0);
        }
      }
    }
  }

  // Desktop menu styles
  &:not(.mobile-menu) {
    height: 100%;

    ul {
      align-items: center;
      flex-direction: row;
      gap: var(--kui-space-20, $kui-space-20);
      height: 100%;

      @media (min-width: $kui-breakpoint-phablet) {
        gap: var(--kui-space-40, $kui-space-40);
      }

      li, a {
        height: 100%;
      }

      li {
        border-bottom-color: var(--kui-color-border-transparent, $kui-color-border-transparent);
        border-bottom-style: solid;
        border-bottom-width: var(--kui-border-width-30, $kui-border-width-30);
        // Transparent border top to center items vertically
        border-top: var(--kui-border-width-30, $kui-border-width-30) solid var(--kui-color-border-transparent, $kui-color-border-transparent);
        transition: border-bottom-color 0.3s ease-in-out;

        // Active link style
        &:has(a.active) {
          border-bottom-color: var(--kui-color-border-primary, $kui-color-border-primary);

          a {
            font-weight: var(--kui-font-weight-medium, $kui-font-weight-medium);
          }
        }
      }

      a {
        color: var(--kui-color-text-inverse, $kui-color-text-inverse);
        font-size: var(--kui-font-size-40, $kui-font-size-40);
        padding: var(--kui-space-30, $kui-space-30) var(--kui-space-50, $kui-space-50);
        text-decoration: none;
        user-select: none;
        white-space: nowrap;

        // Hide the external link icon
        &[target="_blank"] {
          background-image: none !important;
          padding-right: var(--kui-space-0, $kui-space-0);
        }
      }
    }
  }
}
</style>
