<template>
  <div class="header-account">
    <div
      v-if="!session.authenticated"
      class="account-actions"
    >
      <KButton
        appearance="tertiary"
        class="header-log-in-button"
        data-testid="header-log-in-button"
        :to="{ name: 'login-login_path'}"
      >
        {{ t('actions.log_in') }}
      </KButton>
      <KButton
        appearance="primary"
        data-testid="header-sign-up-button"
        :to="{ name: 'register'}"
      >
        {{ t('actions.sign_up') }}
      </KButton>
    </div>
    <div
      v-else
      class="account-dropdown"
    >
      <KDropdown
        :items="dropdownItems"
        :kpop-attributes="{ placement: 'bottom-end' }"
      >
        <button
          aria-label="Toggle account dropdown"
          class="account-dropdown-trigger"
          data-testid="account-dropdown-trigger"
          type="button"
        >
          <ProfileIcon
            decorative
            :size="KUI_ICON_SIZE_50"
          />
        </button>
        <template #items>
          <KDropdownItem
            v-if="session.developer?.full_name || session.developer?.email"
            class="account-dropdown-developer-info"
          >
            <div
              class="developer-info"
              @click.stop
            >
              <div
                v-if="session.developer?.full_name"
                class="developer-name"
              >
                {{ session.developer.full_name }}
              </div>
              <div
                v-if="session.developer?.email"
                class="developer-email"
              >
                {{ session.developer.email }}
              </div>
            </div>
          </KDropdownItem>
          <KDropdownItem
            v-for="option in dropdownItems"
            :key="option.label"
            :class="['account-dropdown-item', { 'has-divider': option.hasDivider }]"
            :danger="option.danger"
            :has-divider="option.hasDivider"
            @click.prevent="goToUrl(option.to)"
          >
            {{ option.label }}
          </KDropdownItem>
        </template>
      </KDropdown>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProfileIcon } from '@kong/icons'
import { KButton, type DropdownItem } from '@kong/kongponents'
import { KUI_ICON_SIZE_50 } from '@kong/design-tokens'
import type { RouteLocationRaw } from '#vue-router'

const { t } = useI18n()
const { session } = storeToRefs(useSessionStore())

const dropdownItems: DropdownItem[] = [
  {
    label: t('pages.apps.title'),
    to: { path: '/apps' },
    hasDivider: true, // The first item should have a divider
  },
  {
    label: t('actions.log_out'),
    to: { path: '/logout' },
    hasDivider: true, // Log out action should have a divider
  },
]

// Navigate to the provided path
const goToUrl = async (path: RouteLocationRaw | undefined | null): Promise<void> => {
  await navigateTo(path)
}
</script>

<style lang="scss" scoped>
.account-actions {
  display: flex;
  gap: var(--kui-space-20, $kui-space-20);

  @media (min-width: $kui-breakpoint-mobile) {
    gap: var(--kui-space-40, $kui-space-40);
  }
}

.header-log-in-button.tertiary {
  color: var(--kui-color-text-inverse, $kui-color-text-inverse);
}

.account-dropdown {
  color: $kui-color-text-inverse;

  :deep(.dropdown-trigger) {
    height: v-bind('KUI_ICON_SIZE_50');
  }
}

.account-dropdown-trigger {
  align-items: center;
  background-color: var(--kui-color-background-transparent, $kui-color-background-transparent);
  border: none;
  border-radius: var(--kui-border-radius-round, $kui-border-radius-round);
  color: var(--kui-color-text-inverse, $kui-color-text-inverse);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  justify-self: flex-end;
  padding: var(--kui-space-0, $kui-space-0);
}

.account-dropdown-developer-info {
  user-select: none;

  :deep(.dropdown-item-trigger) {
    &:hover {
      background-color: var(--kui-color-background, $kui-color-background) !important;
      cursor: auto !important;
    }
  }

  .developer-info {
    max-width: 100%;
  }

  .developer-name {
    font-size: var(--kui-font-size-30, $kui-font-size-30);
    line-height: var(--kui-line-height-30, $kui-line-height-30);
  }

  .developer-email {
    color: var(--kui-color-text-neutral, $kui-color-text-neutral);
    font-size: var(--kui-font-size-20, $kui-font-size-20);
    line-height: var(--kui-line-height-20, $kui-line-height-20);
  }

  // Truncate the strings if too long
  .developer-name,
  .developer-email {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
