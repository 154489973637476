<template>
  <Teleport to="#teleports">
    <KModal
      close-on-backdrop-click
      close-on-escape
      :visible="visible"
      @cancel="emit('close')"
    >
      <template #content>
        <div class="search-modal-content">
          <KInput
            v-model="searchQuery"
            class="search-input"
            data-testid="search-input"
            :placeholder="t('actions.type_to_search')"
            type="search"
          >
            <template #before>
              <SearchIcon decorative />
            </template>
            <template
              v-if="searchQuery!!"
              #after
            >
              <button
                class="clear-search-query"
                @click="searchQuery = ''"
              >
                <CloseIcon />
              </button>
            </template>
          </KInput>

          <KButton
            appearance="secondary"
            type="button"
            @click="emit('close')"
          >
            {{ t('actions.close') }}
          </KButton>
        </div>
      </template>
    </KModal>
  </Teleport>
</template>

<script setup lang="ts">
import { SearchIcon, CloseIcon } from '@kong/icons'

defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits<{
  (e: 'close'): void
}>()
const { t } = useI18n()
const searchQuery = useState<string>(() => '')

onUnmounted(() => {
  searchQuery.value = ''
})
</script>

<style lang="scss" scoped>
.search-modal-content {
  padding: var(--kui-space-70, $kui-space-70);
}

.search-input {
  margin-bottom: var(--kui-space-70, $kui-space-70);

  :deep(input[type="search"]) {
    &::-webkit-search-cancel-button {
      display: none;
    }
  }
}

.clear-search-query {
  align-items: center;
  border: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
}
</style>
