<template>
  <kbd class="search-command" />
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: 'search'): void
  (e: 'mac', isMac: boolean): void
}>()

const bindSearchShortcut = (e: KeyboardEvent) => {
  if (e.code === 'KeyK' && (e.ctrlKey || e.metaKey)) {
    e.preventDefault()
    emit('search')
  }
}

onMounted(() => {
  // Set `isMac` to true if on Mac (for shortcut icons)
  // @ts-ignore - property exists
  const isMac = /Mac|iPhone|iPod|iPad/i.test(navigator?.platform) || /macOS|Mac|iPhone|iPod|iPad/i.test(navigator?.userAgentData?.platform)

  emit('mac', isMac)
})

onBeforeMount(() => {
  document.addEventListener('keydown', bindSearchShortcut)
})

onUnmounted(() => {
  document.removeEventListener('keydown', bindSearchShortcut)
})
</script>
