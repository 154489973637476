<template>
  <div class="layout-base-content">
    <div class="layout-content-container">
      <slot name="default" />
    </div>
    <slot name="content-sidebar" />
  </div>
</template>

<style lang="scss" scoped>
.layout-base-content {
  display: flex;
  flex-direction: row;
  gap: var(--kui-space-70, $kui-space-70);
  height: 100%;
  margin: var(--kui-space-0, $kui-space-0) var(--kui-space-auto, $kui-space-auto);
  max-width: var(--kui-breakpoint-laptop, $kui-breakpoint-laptop);
  width: 100%;

  // Important: .layout-content-container class is bound in useTocActiveLink.ts
  .layout-content-container {
    min-width: 0; // Important: By explicitly setting min-width: 0; on the flex item, we can override the default behavior and allow the element to shrink beyond its automatic minimum size.
    padding: var(--kui-space-70, $kui-space-70);
    width: 100%;
  }
}
</style>
