<template>
  <div
    v-if="footerBottomMenuItems?.length"
    class="layout-menu-footer-bottom"
  >
    <div class="footer-bottom-content">
      <div class="footer-bottom-left">
        <HeaderLogo />
      </div>
      <div class="footer-bottom-right">
        <nav>
          <ul>
            <li
              v-for="link of footerBottomMenuItems"
              :key="link.path"
            >
              <NuxtLink
                :external="link.external"
                :href="link.path"
                :target="link.external ? '_blank' : '_self'"
              >
                {{ link.title }}
              </NuxtLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const footerBottomMenuItems = useNavMenu<PortalMenuItem>('footer_bottom')
</script>

<style lang="scss" scoped>
.layout-menu-footer-bottom {
  background-color: var(--kui-color-background-neutral-weaker, $kui-color-background-neutral-weaker);
  width: 100%;

  ul {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: var(--kui-space-20, $kui-space-20) var(--kui-space-70, $kui-space-70);
    justify-content: center;
    list-style: none;
    margin: var(--kui-space-0, $kui-space-0);
    padding: var(--kui-space-0, $kui-space-0);

    @media (min-width: $kui-breakpoint-phablet) {
      justify-content: flex-end;
    }

    li, a {
      display: block;
    }

    a {
      color: var(--kui-color-text-neutral, $kui-color-text-neutral);
      font-size: var(--kui-font-size-30, $kui-font-size-30);
      text-decoration: none;
      transition: color 0.2s ease-in-out;
      user-select: none;
      white-space: nowrap;

      &:hover {
        color: var(--kui-color-text-neutral-strong, $kui-color-text-neutral-strong);
      }

      // Hide the external link icon
      &[target="_blank"] {
        background-image: none !important;
        padding-right: var(--kui-space-0, $kui-space-0);
      }
    }
  }
}

.footer-bottom-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--kui-space-50, $kui-space-50);
  justify-content: space-between;
  margin: var(--kui-space-0, $kui-space-0) var(--kui-space-auto, $kui-space-auto);
  max-width: var(--kui-breakpoint-laptop, $kui-breakpoint-laptop);
  padding: var(--kui-space-70, $kui-space-70);
  width: 100%;

  @media (min-width: $kui-breakpoint-phablet) {
    flex-direction: row;
    gap: var(--kui-space-70, $kui-space-70);
  }
}
</style>
