<template>
  <header class="layout-header">
    <div class="layout-header-content">
      <div class="left">
        <LayoutMenuMobile v-if="menuItems.length" />
        <HeaderLogo responsive />
      </div>
      <div class="center">
        <LayoutMenuHeader class="above-phablet" />
      </div>
      <div class="right">
        <HeaderPreviewMode />
        <SearchButton v-if="info?.is_public || session.authenticated" />
        <HeaderAccount v-if="info?.is_public === false" />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
const { info } = storeToRefs(usePortalStore())
const { session } = storeToRefs(useSessionStore())
const menuItems = useNavMenu('header')
</script>

<style lang="scss" scoped>
.layout-header {
  align-items: center;
  background-color: var(--kui-color-background-inverse, $kui-color-background-inverse);
  color: var(--kui-color-text-inverse, $kui-color-text-inverse);
  display: flex;
  flex: 0 0 $header-height; // must match the height
  flex-direction: column;
  height: $header-height; // must match flex height in app.vue
  justify-content: center;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 101;
}

.layout-header-content {
  align-items: center;
  display: flex;
  gap: var(--kui-space-60, $kui-space-60);
  height: 100%;
  justify-content: space-between;
  padding: var(--kui-space-0, $kui-space-0) var(--kui-space-70, $kui-space-70);
  width: 100%;

  @media (min-width: $kui-breakpoint-phablet) {
    gap: var(--kui-space-70, $kui-space-70);
  }

  .left,
  .right,
  .center {
    align-items: center;
    display: flex;
    gap: var(--kui-space-60, $kui-space-60);
    height: 100%;

    @media (min-width: $kui-breakpoint-phablet) {
      gap: var(--kui-space-70, $kui-space-70);
    }
  }

  .left,
  .right {
    flex: 1 1 0%;
  }

  .right {
    justify-content: flex-end;
  }
}

.above-phablet {
  display: none;

  @media (min-width: $kui-breakpoint-phablet) {
    display: flex;
  }
}
</style>
