/**
 * This plugin is responsible for refreshing the user's session on the server during the initial load of the application.
 *
 * If the portal is not public, the function checks if the refresh cookie is present; if present, the plugin
 * attempts to refresh the session by making a request to the proxied refresh endpoint via $fetchWithCookie.
 * - If the refresh is successful, the session is marked as authenticated.
 * - If the refresh fails, the user's session is destroyed, then marks the session as unauthenticated, and the current route is stored in a cookie to redirect on successful login.
 *
 * If the refresh cookie is not present, the user's session is destroyed in the same way as described above. The session is marked as unauthenticated and the current route is stored in the cookie to redirect on login.
 */
export default defineNuxtPlugin({
  name: 'session-refresh',
  dependsOn: ['fetch-portal-data', 'open-fetch-sdks'],
  async setup() {
    const { info } = storeToRefs(usePortalStore())
    const sessionStore = useSessionStore()
    const { session } = storeToRefs(sessionStore)
    const { portalAccessToken, portalSessionToken } = useRuntimeConfig().public.cookies
    const cookieAccess = useCookie(portalAccessToken)
    const cookieSession = useCookie(portalSessionToken)
    const route = useRoute()
    const event = useRequestEvent()
    const { $fetchWithCookie, $portalApi, $i18n } = useNuxtApp()

    // If Portal is public, no need to attempt to refresh the session
    if (info.value?.is_public === true) {
      return
    }

    // Since the Portal is not public, if the session environment variables are missing on the server, throw an error
    if (import.meta.server) {
      // If session password is missing
      if (!useRuntimeConfig().portalSessionPassword) {
        throw createError({
          statusCode: 500,
          statusMessage: $i18n.t('errors.env.missing_variable', { variable: 'NUXT_PORTAL_SESSION_PASSWORD' }),
          fatal: true,
        })
      }
    }

    // A local function to log out the user
    const logoutUser = async () => {
      // Important: Destroy the user's session
      try {
        // Log out the user using the method appropriate for the environment
        if (import.meta.server) {
          // Important: Must call a proxied endpoint in order to rewrite cookies
          await $fetchWithCookie(event!, '/api/session/logout', {
            method: 'POST',
          })
        } else {
          // Important: Must call a proxied endpoint in order to rewrite cookies
          await $fetch('/api/session/logout', {
            method: 'POST',
          })
        }
      } catch {
        // no-op
      } finally {
        // Important: Set the authenticated session value to false (this will also refresh the portal config data)
        session.value.authenticated = false
      }
    }

    // If the portal is private and the user is not authenticated
    if (info.value?.is_public === false && !session.value.authenticated) {
      // If the session cookie is present, or in the client, and not on the Logout route, attempt to refresh the session
      if ((import.meta.client || cookieSession.value) && !route.path.includes('/logout')) {
        try {
          // If on the server, and there is not an access cookie, and there is a session cookie, attempt to refresh the session
          if (import.meta.server && !cookieAccess.value && cookieSession.value) {
            await $fetchWithCookie(event!, '/api/session/refresh', {
              method: 'POST',
            })
          }

          // We always allow the /developer/me call below on the client and server to verify
          // that the user has an active session before setting `session.authenticated` to true.
          // This creates a 401 error on unauthenticated pages if the user is missing their token; however,
          // it properly allows the session to be restored if the user has a valid refresh token.

          const unauthenticatedAllowedPaths = ['/login', '/login/sso', '/logout', '/register', '/forgot-password', '/reset-password']
          // If on the server, or on the client and on an unauthenticated path, do not retry the session data request
          const shouldRetryRequest = import.meta.server || (import.meta.client && unauthenticatedAllowedPaths.includes(route.path)) ? false : undefined

          // Retrieve the developer's session data
          const developerData = await $portalApi('/api/v2/developer/me', {
            method: 'GET',
            retry: shouldRetryRequest,
          })
          // Store the developer data in the session store
          session.value.developer = developerData

          // Important: Set the authenticated session value to true (this will also refresh the portal config data)
          session.value.authenticated = true
        } catch (error: any) {
          // Log out the user
          await logoutUser()

          // Store the current route in the cookie to redirect on login
          sessionStore.setLoginReturnPath(route.fullPath)
        }
      } else {
        // Log out the user
        await logoutUser()

        // Store the current route in the cookie to redirect on login
        sessionStore.setLoginReturnPath(route.fullPath)
      }
    }
  },
})
