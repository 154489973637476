export const useLayoutStore = defineStore('layout', () => {
  /** Is the mobile menu visible */
  const sidebarMobileMenuIsVisible = ref<boolean>(false)
  /** On mobile, is the sidebar documents list visible */
  const sidebarDocumentsListIsVisible = ref<boolean>(false)
  /** On mobile, is the sidebar document toc visible */
  const sidebarDocumentTocIsVisible = ref<boolean>(false)

  /** Close all sidebars on mobile */
  const closeAllSidebars = (): void => {
    sidebarMobileMenuIsVisible.value = false
    sidebarDocumentsListIsVisible.value = false
    sidebarDocumentTocIsVisible.value = false
  }

  /** If true, show the .global-page-overlay */
  const showPageOverlay = computed((): boolean => {
    return sidebarMobileMenuIsVisible.value ||
    sidebarDocumentsListIsVisible.value ||
    sidebarDocumentTocIsVisible.value
  })

  // Hide other sidebars when the sidebarMobileMenuIsVisible is true
  watch(sidebarMobileMenuIsVisible, (isVisible: boolean) => {
    if (isVisible) {
      sidebarDocumentsListIsVisible.value = false
      sidebarDocumentTocIsVisible.value = false
    }
  })

  // Hide other sidebars when the sidebarDocumentsListIsVisible is true
  watch(sidebarDocumentsListIsVisible, (isVisible: boolean) => {
    if (isVisible) {
      sidebarMobileMenuIsVisible.value = false
      sidebarDocumentTocIsVisible.value = false
    }
  })

  // Hide other sidebars when the sidebarDocumentTocIsVisible is true
  watch(sidebarDocumentTocIsVisible, (isVisible: boolean) => {
    if (isVisible) {
      sidebarMobileMenuIsVisible.value = false
      sidebarDocumentsListIsVisible.value = false
    }
  })

  return {
    sidebarMobileMenuIsVisible,
    sidebarDocumentsListIsVisible,
    sidebarDocumentTocIsVisible,
    showPageOverlay,
    // Methods
    closeAllSidebars,
  }
})
