<template>
  <footer class="layout-footer">
    <LayoutMenuFooterSections />
    <LayoutMenuFooterBottom />
  </footer>
</template>

<style lang="scss" scoped>
.layout-footer {
  align-items: flex-start;
  background-color: var(--kui-color-background, $kui-color-background);
  border-top: var(--kui-border-width-10, $kui-border-width-10) solid var(--kui-color-border, $kui-color-border);
  color: var(--kui-color-text-inverse, $kui-color-text-inverse);
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  padding: var(--kui-space-0, $kui-space-0);
  width: 100%;
}
</style>
