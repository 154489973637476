<template>
  <div
    :id="componentId"
    class="portal-logo"
    :class="{ responsive }"
  >
    <img
      v-if="appearance?.variables?.catalog?.logo?.url"
      :alt="info?.name"
      class="main-logo"
      :src="`/api${appearance.variables.catalog.logo.url}`"
    >
    <!-- TODO: Add logic for mobile logo once supported; for now mobile to icon -->
    <div class="mobile-logo">
      <DevPortalIcon
        as="div"
        :size="KUI_ICON_SIZE_50"
        :title="info?.name"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { DevPortalIcon } from '@kong/icons'
import { KUI_ICON_SIZE_50 } from '@kong/design-tokens'

const { info, appearance } = storeToRefs(usePortalStore())

const props = defineProps({
  width: {
    type: Number,
    default: 200,
  },
  /** Should the default logo switch to the mobile logo on smaller viewports? */
  responsive: {
    type: Boolean,
    default: false,
  },
  /** Provide custom CSS rules, scoped to this component */
  styles: {
    type: String,
    required: false,
    default: '',
  },
})
// Inject any custom `props.styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => props.styles), useAttrs().id as string)
const imageWidth = computed((): string => `${props.width}px`)
</script>

<style lang="scss" scoped>
.portal-logo {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  img {
    display: block;
    margin: var(--kui-space-0, $kui-space-0) var(--kui-space-auto, $kui-space-auto);
    max-width: v-bind(imageWidth);
  }

  &.responsive {
    .main-logo {
      display: none;

      @media (min-width: $kui-breakpoint-phablet) {
        display: flex;
      }
    }

    .mobile-logo {
      display: flex;

      @media (min-width: $kui-breakpoint-phablet) {
        display: none;
      }
    }
  }
}

.mobile-logo {
  align-items: center;
  display: none; // Display none by default; controlled by .responsive class in parent
  gap: var(--kui-space-40, $kui-space-40);
}
</style>
