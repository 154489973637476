<template>
  <KPop
    v-if="enabled"
    close-on-popover-click
    hide-close-icon
    placement="bottom-end"
    trigger="click"
    width="280px"
  >
    <button
      class="preview-mode-button"
      :title="t('preview_mode.click_for_more_info')"
      type="button"
    >
      <KBadge
        appearance="success"
        :icon-before="false"
      >
        <template #icon>
          <span class="pulse">
            <InfoIcon />
          </span>
        </template>
        {{ t('preview_mode.live_preview') }}
      </KBadge>
    </button>

    <template #content>
      <div
        class="popup-content"
        @click.stop
      >
        <h4>{{ t('preview_mode.enabled') }}</h4>
        <p>{{ t('preview_mode.description') }}</p>

        <!-- TODO: Hide the share option until Preview Mode KV Storage/Retrieval is supported -->
        <!-- <KInput
          v-if="previewUrl"
          data-testid="preview-url-input"
          :help="t('preview_mode.link_help')"
          label="Share preview"
          :model-value="previewUrl"
          readonly
        >
          <template #after>
            <CopyButton :text="previewUrl" />
          </template>
        </KInput> -->
      </div>
    </template>
    <template #footer>
      <div class="popup-actions">
        <KButton
          appearance="secondary"
          size="medium"
        >
          {{ t('actions.dismiss') }}
        </KButton>
        <KButton
          v-if="showExitButton"
          appearance="primary"
          size="medium"
          @click="exitPreviewMode"
        >
          {{ t('actions.preview_mode_exit') }}
        </KButton>
      </div>
    </template>
  </KPop>
</template>

<script setup lang="ts">
import { InfoIcon } from '@kong/icons'

const { enabled, state } = usePortalPreviewMode()
const { t } = useI18n()
const route = useRoute()
const router = useRouter()

// Unused for now
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const previewUrl = computed((): string => {
  if (!import.meta.client) {
    return ''
  }
  const currentRoute = router.resolve({
    path: route.fullPath,
    query: {
      preview: 'true',
      preview_id: state.value.preview_id,
    },
  }).fullPath

  return `${window.location.origin}${currentRoute}`
})

// Disable preview mode and trigger the usePortalPreviewMode composable to remove the preview mode cookies
const exitPreviewMode = async (): Promise<void> => {
  // Disable preview mode
  enabled.value = false
}

const showExitButton = useState<boolean>(() => true)
onMounted(() => {
  // Determine if the app is running inside an iframe, and if yes, hide the exit button
  showExitButton.value = !(typeof window !== 'undefined' && window.self !== window.top)
})
</script>

<style lang="scss" scoped>
.badge {
  &-mobile {
    @media (min-width: $kui-breakpoint-laptop) {
      display: none;
    }
  }
  &-desktop {
    display: none;
    @media (min-width: $kui-breakpoint-laptop) {
      display: block;
    }
  }
}

.preview-mode-button {
  align-items: center;
  background-color: var(--kui-color-background-transparent, $kui-color-background-transparent);
  border: none;
  color: var(--kui-color-text-inverse, $kui-color-text-inverse);
  cursor: pointer;
  display: flex;
  gap: var(--kui-space-20, $kui-space-20);
  justify-content: center;
  padding: var(--kui-space-0, $kui-space-0);
  position: relative;
  user-select: none;
  white-space: nowrap;
}

.popup-content {
  font-size: var(--kui-font-size-30, $kui-font-size-30);
  line-height: var(--kui-line-height-30, $kui-line-height-30);
}

.popup-actions {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: var(--kui-space-50, $kui-space-50);
  justify-content: flex-end;
  margin-top: var(--kui-space-70, $kui-space-70);
  width: 100%;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.pulse {
  animation: pulse-animation 2s infinite;
  background: transparent;
  border-radius: var(--kui-border-radius-circle, $kui-border-radius-circle);
  overflow: visible;
  position: relative;
  z-index: 1;

  // Disable animation if user prefers reduced motion
  @media(prefers-reduced-motion: reduce) {
    animation: none;
  }
}
</style>
