import { COOKIE_PREVIEW_MODE, COOKIE_PREVIEW_ID } from '../constants/preview-mode'

export const useSessionStore = defineStore('session', () => {
  // Session data
  const session = ref<Session>({
    authenticated: false, // Initialize as false
    developer: undefined, // Initialize as undefined
  })

  const { cookies: { portalLoginReturnPath }, portal: { origin: localPortalOrigin } } = useRuntimeConfig().public
  const portalConfigStore = usePortalStore()
  const url = useRequestURL()
  const { identifyUser } = useLaunchDarkly()

  // Watch for changes to the authenticated state
  watch(() => session.value.authenticated, async (isAuthenticated) => {
    // Refresh the portal configs when the user logs in or out
    await portalConfigStore.refreshPortalConfigs()

    // If not authenticated
    if (!isAuthenticated) {
      // Clear out the developer data
      session.value.developer = undefined
      // Clear Nuxt data
      clearNuxtData()
    }

    // Re-identify the user with LaunchDarkly
    await identifyUser()
  })

  /**
   * Store the unauthenticated user's desired path to a cookie in order to redirect the user to the path upon successful login.
   *
   * @param {string | null} returnPath - The return path to be set.
   */
  const setLoginReturnPath = (returnPath: string | null): void => {
    try {
      // Check if the Nuxt app context is available; if not, exit early
      if (!tryUseNuxtApp()) {
        return
      }

      // If the return path is not set or is empty, set the cookie to null (must be null, not undefined or empty string)
      let cookieReturnPath = !returnPath || String(returnPath || '').trim() === '' ? null : returnPath

      // No need to store certain pages and paths in the cookie
      const ignoredReturnPaths = ['/login', '/logout', '/register', '/forgot-password', '/reset-password', '/api/', '/_assets/', '/_nuxt', '/__nuxt']
      // If the desired route is the homepage or in the ignore list, do not store the path and just exit early
      if (returnPath !== null && (returnPath === '/' || ignoredReturnPaths.some(ignoredPath => returnPath?.startsWith(ignoredPath)))) {
        return
      }

      // Remove any preview mode query parameters from the return path
      const [baseReturnPath, queryString] = cookieReturnPath?.split('?') || []
      if (queryString) {
        const searchParams = new URLSearchParams(queryString)
        // Remove undesired query parameters
        for (const param of [COOKIE_PREVIEW_MODE, COOKIE_PREVIEW_ID]) {
          searchParams.delete(param)
        }
        const newQueryString = searchParams.toString()
        cookieReturnPath = newQueryString ? `${baseReturnPath}?${newQueryString}` : baseReturnPath
      }

      // Create the cookie
      const returnPathCookie = useCookie(portalLoginReturnPath, {
        path: '/',
        domain: localPortalOrigin || url.hostname === 'localhost' ? undefined : url.hostname, // Scope the cookie to the current subdomain
        maxAge: cookieReturnPath ? 3600 : 0, // Max age of 1 hour if set, otherwise set the Max-Age to 0 to delete the cookie
        sameSite: localPortalOrigin ? false : 'none' as (boolean | 'lax' | 'strict' | 'none' | undefined), // Set SameSite to false on localhost (for Safari cookie compatibility) and to 'lax' otherwise
        httpOnly: false,
        secure: localPortalOrigin ? false : true, // Set Secure to false on localhost (for Safari cookie compatibility)
        partitioned: localPortalOrigin ? false : true, // Set to false on localhost (for Safari cookie compatibility)
      })

      // In case the cookie already exists, we need to explicitly set the value
      returnPathCookie.value = cookieReturnPath
    } catch (error: any) {
      // no-op: Do not throw an error if the cookie cannot be set
    }
  }

  /**
   * Retrieves the login return path from the cookie.
   * @returns {string | null} The login return path if available, otherwise null.
   */
  const getLoginReturnPath = (): string | null => {
    try {
      return useCookie(portalLoginReturnPath).value || null
    } catch {
      return null
    }
  }

  return {
    session,
    // Methods
    getLoginReturnPath,
    setLoginReturnPath,
  }
})
