/**
 * Capitalize the first character of a string.
 *
 * @param {string} s The string to capitalize.
 * @returns {string} The string with the first character capitalized.
 */
export const capitalizeFirstCharacter = (s: string): string => {
  if (typeof s !== 'string') return s

  const str = s.trim()
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Truncates a string to a specified length and adds an ellipsis if necessary.
 *
 * @param {string} s The string to truncate.
 * @param {number} allowedLength The maximum length of the truncated string. Default is 200.
 * @returns {string} The truncated string.
 * @example const description = truncateString('This is a long description that needs to be truncated.', 10)
 */
export const truncateString = (s: string, allowedLength: number = 200): string => {
  if (!s) {
    return ''
  }

  const allowedLengthWithEllipsis = allowedLength - 3

  return s.length > allowedLengthWithEllipsis ? `${s.slice(0, allowedLengthWithEllipsis)}...` : s
}

/**
 * Checks if a string is a valid UUID.
 * @param {string} uuid - The string to be checked.
 * @returns {boolean} A boolean indicating whether the string is a valid UUID.
 */
export const isValidUUID = (uuid: string): boolean => {
  if (!uuid) {
    return false
  }

  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i

  return uuidRegex.test(uuid)
}

/**
 * Adds a leading slash to a string if it doesn't already have one.
 *
 * @param {string} str - The string to add a leading slash to.
 * @returns {string} The string with a leading slash added.
 */
export const addLeadingSlash = (str: string): string => {
  // If path does not start with a leading slash, add it
  return !str ? '' : `/${str.replace(/^\/+/, '')}`
}
